import React from "react"
import IndexPage from "../components/index-page";
import Seo from "../components/seo";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { TitleBar } from "../components/titlebar";



const PrinciplePage = ({pageContext, location}) => {
  const blurb = (<React.Fragment><p class="lead">We've been lucky to work with many great clients, on interesting projects.</p></React.Fragment>)

  const title = "Our Experience"
  const slug = "/experience"
  
  return (<React.Fragment>
    <Header containerClasses="container" />
    <Seo title={title} pathname={slug} description={title} />
    <TitleBar title={title} location={location} crumbs={pageContext.breadcrumb}/>
    <IndexPage title={title} startPath="/experience/" blurb={blurb} location={location}/>
    <Footer containerClasses="container" />
  
  </React.Fragment>)
  
}
// Step 3: Export your component
export default PrinciplePage